import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';
import { PortafolioComponent } from './pages/portafolio/portafolio.component';
import { AboutComponent } from './pages/about/about.component';
import { ItemComponent } from './pages/item/item.component';

const app_routes : Routes = [
	{ path : 'home',      component  : HomeComponent },
	/*{ path : 'portafolio',      component  : PortafolioComponent },
	{ path : 'about', component  : AboutComponent },
	{ path : 'item',  component  : ItemComponent },*/
	{ path: '', redirectTo: '/home', pathMatch: 'full' },
	{ path : '**',    pathMatch  : 'full', redirectTo : '/home' },
];

@NgModule({
	imports: [
		RouterModule.forRoot( app_routes )
	],
	exports: [ RouterModule ]
})

export class AppRoutingModule { }
